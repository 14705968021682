/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

.ProductList {
    &-LoadMoreButtonWrapper {
        text-align: center;

        @include mobile {
            margin-inline: 16px;
        }
    }

    &-LoadMoreButton {
        z-index: 0;
        cursor: pointer;
        font-size: 16px;

        @include mobile {
            width: auto;
        }

        &:hover {
            @include desktop {
                border-bottom-style: solid;
                border-width: var(--button-border-width);
            }
        }
    }
}
